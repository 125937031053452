<template>
  <v-container>
    <vue-snotify></vue-snotify>
    <v-card>
      <v-card-title class="cyan darken-1 card-sipymex">
        <v-row>
          <v-col cols="12" md="9">
            <span class="white--text">Deudas</span>
          </v-col>
          <v-col cols="12" md="3"> </v-col>
        </v-row>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12" md="8"></v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            >
            </v-text-field>
          </v-col>
        </v-row>
        <br />

        <v-data-table
          :headers="headers"
          :items="debts"
          :search="search"
          :footer-props="{
            itemsPerPageText: 'Filas por página',
            itemsPerPageAllText: 'todos',
          }"
          no-data-text="No hay datos disponibles"
          no-results-text="No hay datos disponibles"
        >
          <template v-slot:item.name_customer="{ item }">
            <span>{{ getNameCustomerByIdContract(item.id_contract) }}</span>
          </template>

          <template v-slot:item.name_plan="{ item }">
            <span>{{ getNamePlanByIdContract(item.id_contract) }}</span>
          </template>

          <template v-slot:item.billing_period="{ item }">
            <span>{{
              getDayBillingPeriodsByIdContract(item.id_contract)
            }}</span>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon :to="'/update-debt/' + item.id">
                  <v-icon v-bind="attrs" color="primary" v-on="on">
                    mdi-pencil-box-multiple
                  </v-icon>
                </v-btn>
              </template>

              <span>Modificar</span>
            </v-tooltip>

            <v-dialog transition="dialog-bottom-transition" max-width="600">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon v-bind="attrs" color="red darken-2" v-on="on">
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <template v-slot:default="dialog">
                <v-card>
                  <v-toolbar color="primary" dark>Mensaje</v-toolbar>
                  <v-card-text>
                    <div class="text-h7 pa-6">
                      ¿Estás seguro que deseas eliminar la deuda?
                    </div>
                  </v-card-text>
                  <v-card-actions class="justify-end">
                    <v-btn text @click="dialog.value = false">Cancelar</v-btn>
                    <v-btn
                      class="primary"
                      text
                      @click="
                        dialog.value = false;
                        deleteItem(item);
                      "
                      >Aceptar</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </template>
            </v-dialog>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import axios from "axios";
import VueSnotify from "vue-snotify";
import SnotifyMixin from "@/mixins/DisplayNotificationMixin.vue";

export default {
  mixins: [SnotifyMixin],
  VueSnotify,

  data: () => ({
    dialog: false,
    search: "",
    headers: [
      { text: "Empresa", value: "name_customer" },
      { text: "Plan", value: "name_plan" },
      { text: "Día de Facturación", value: "billing_period" },
      { text: "Monto de Deuda", value: "price" },
      { text: "Fecha de Deuda", value: "date" },
    ],

    debts: [],
    contracts: [],
    customers: [],
    plans: [],
    billing_periods: [],
  }),

  computed: {},
  mounted() {
    this.action = "Eliminar";
    this.getCustomers();
    this.getContracts();
    this.getPlans();
    this.getBillingPeriods();
    this.getDebts();
  },

  watch: {},

  methods: {
    getDebts() {
      axios
        .get("/debts")
        .then((response) => {
          console.log("Deuda");
          console.log(response.data);
          this.debts = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener las deduas"
          );
        });
    },

    getNameCustomer(id_customer) {
      //  this.getServices();
      let customer = this.customers.find(
        (customer) => customer.id === id_customer
      );

      return customer.legal_name;
    },

    getNamePlan(id_plan) {
      //  this.getServices();
      let plan = this.plans.find((plan) => plan.id === id_plan);

      return plan.name;
    },

    getDayBillingPeriod(id_billing_period) {
      console.log("BillingPeriodName");
      console.log(id_billing_period);
      let billing = this.billing_periods.find(
        (billing) => billing.id === id_billing_period
      );

      return billing.billing_period;
    },

    deleteItem(item) {
      console.log("eliminando");
      console.log(item);
      let request = {
        id: item.id,
      };
      axios
        .post("/destroy-debt", request)
        .then((response) => {
          this.getDebts();
          this.displayNotification(
            "success",
            "Éxito",
            "Deuda eliminada correctamente"
          );
          console.log(response.data);
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "No se pudo eliminar la deuda"
          );
        });
    },

    getCustomers() {
      axios
        .get("/customers")
        .then((response) => {
          console.log("Clientes");
          console.log(response.data);
          this.customers = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error en la obtención de clientes"
          );
        });
    },
    getPlans() {
      axios
        .get("/plans")
        .then((response) => {
          console.log("Plans");
          console.log(response.data);
          this.plans = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error en la obtención de planes"
          );
        });
    },

    getContracts() {
      axios
        .get("/contracts")
        .then((response) => {
          console.log(response.data);
          this.contracts = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error en la obtención de contratos"
          );
        });
    },

    getBillingPeriods() {
      axios
        .get("/billing-periods")
        .then((response) => {
          console.log("Imprimiendo BillingPeriods");
          console.log(response.data);
          this.billing_periods = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error en la obtención de días de facturación"
          );
        });
    },

    getContractById(id_contract) {
      let contract = this.contracts.find((c) => c.id === id_contract);
      return contract;
    },
    getNameCustomerByIdContract(id_contract) {
      let contract = this.getContractById(id_contract);
      let name = this.getNameCustomer(contract.id_customer);
      return name;
    },
    getNamePlanByIdContract(id_contract) {
      let contract = this.getContractById(id_contract);
      let name = this.getNamePlan(contract.id_plan);
      return name;
    },

    getDayBillingPeriodsByIdContract(id_contract) {
      let contract = this.getContractById(id_contract);
      let name = this.getDayBillingPeriod(contract.id_billing_periods);

      console.log("getDayBillingPeriodsByIdContract");
      console.log("contract.id_billing_periods ");
      console.log(name);
      return name;
    },
  },
};
</script>